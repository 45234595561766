.ql-toolbar.ql-snow {
  width: 100%;
  // height: 45px;
  border-width: 2px 0;
  border-color: #f2f2f2;
  display: flex;
  flex-wrap: wrap;
  row-gap: 4px;
  // overflow-x: auto;
  padding: 8px 16px;
}

.ql-container.ql-snow {
  border: 0;
}

/* Scroll Bar styles start */

.ql-editor,
.ql-picker .ql-picker-options {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.ql-toolbar.ql-snow::-webkit-scrollbar,
.ql-editor::-webkit-scrollbar,
.ql-picker .ql-picker-options::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f4f4f6;
}

.ql-toolbar.ql-snow::-webkit-scrollbar-thumb,
.ql-editor::-webkit-scrollbar-thumb,
.ql-picker .ql-picker-options::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #ccc;
}

/* Horizontal Scroll Bar styles End */

.ql-formats {
  flex-shrink: 0;
}

/* Font size updates start */
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "Small";
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
  content: "Normal";
  font-size: 13px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "Medium";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "Large";
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "Huge";
  font-size: 24px !important;
}

.ql-picker-label.ql-active[data-value="13px"]::before {
  content: "Normal" !important;
}
.ql-picker-label.ql-active[data-value="10px"]::before {
  content: "Small" !important;
}
.ql-picker-label.ql-active[data-value="16px"]::before {
  content: "Medium" !important;
}
.ql-picker-label.ql-active[data-value="18px"]::before {
  content: "Large" !important;
}
.ql-picker-label.ql-active[data-value="24px"]::before {
  content: "Huge" !important;
}
/* Font size updates end */
.ql-snow .ql-picker.ql-size {
  width: auto;
}

.ql-snow .ql-picker-label {
  padding-left: unset;
  padding-right: 18px;
}

/* Styles for Placeholders in editor and placeholder format button in toolbar */
.custom_email_placeholder:not(span.-no-style) {
  background: rgb(210, 228, 249);
  padding: 0px 3px;
  /* border: 1px solid #ccc; */
  border-radius: 2px;
}

.ql-picker.ql-email-placeholder .ql-picker-label::before {
  content: "Insert Placeholder";
}

.ql-picker.ql-email-placeholder .ql-picker-label.ql-active::before {
  content: attr(data-title);
}

.ql-picker.ql-email-placeholder .ql-picker-options {
  text-align: left;
  max-height: 200px;
}

/* hiding outline when any dropdown from toolbar opens */
.ql-formats .ql-picker-label[aria-expanded="true"] {
  outline: none;
}

/* Custom Icon styles */
.ql-snow.ql-toolbar .ql-dk_attachment {
  padding-right: 2px;
  width: auto;

  svg {
    padding: 2px 0;
  }

  .ql-stroke {
    fill: #222;
    stroke-width: 16px;
  }

  &:hover .ql-stroke {
    fill: #06c;
  }
}

.ql-snow.ql-toolbar .ql-email-template {
  width: 8.5em;
  position: relative;
  text-align: right;
  padding: 0;
  margin-right: 16px;

  .template-selector-button {
    padding-left: 7.8em;
  }

  &::before {
    content: "Insert Template";
    position: absolute;
    left: 0;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;
    color: #444;
    padding-left: 4px;
  }
}

.add-email-template-popup .dkinput-header-section {
  width: 80px;
}

.email-body-required .ql-editor.ql-blank::before {
  content: "Please provide email content!" !important;
  color: rgb(203, 26, 38) !important;
}

@import "../../styles/variables.scss";

.first-letter-capital {
  &::first-letter {
    text-transform: uppercase;
  }
}

.text-ellipsis {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.smtp-help-tooltip {
  .smtp-help-banner {
    display: none;
  }

  &:hover {
    padding-right: 32px;

    .smtp-help-banner {
      display: flex;
    }
  }
}

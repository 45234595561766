.website-redesign-holder {
  font-family:
    Inter,
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Helvetica Neue,
    Arial,
    Noto Sans,
    sans-serif,
    Apple Color Emoji,
    Segoe UI Emoji,
    Segoe UI Symbol,
    Noto Color Emoji;
}

.website-redesign-holder .eyebrow-label {
  text-transform: uppercase;
  color: rgb(22, 100, 215);
}

.website-redesign-holder .theme-switcher {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  flex-shrink: 0;
  font-size: 1.5rem;
}

.showcase .ic-huge {
  height: 70px;
}

.bouncing-scroll-arrow {
  padding: 16px 0;
  animation: bounce 2s ease-in-out infinite;
}

.text-maroon {
  color: #ae2f02;
}

.text-black {
  color: rgb(17, 24, 39);
}

@keyframes bounce {
  0% {
    bottom: 72px;
  }
  50% {
    bottom: 112px;
  }
  100% {
    bottom: 72px;
  }
}

.deskera-website-header {
  position: sticky;
  top: 0;
  background: inherit;
  padding: 2rem 10rem;
  z-index: 10;
  transition: all 0.2s;
}

.deskera-website-header.-floating {
  padding: 1rem 10rem;
}

.deskera-website-header.-floating.-dark {
  box-shadow: 0 0 5px rgba(143, 99, 231, 0.7);
}

.deskera-website-header.-floating.-light {
  box-shadow: 5px 0 15px 2px hsla(0, 0%, 75%, 0.3);
}
.plan-button-group {
  border-bottom: 1px solid #dcdcdc;
}
.plan-button-group.dark {
  border-bottom: 1px solid #fff;
}
.plan-button-group .plan-button {
  min-width: 15rem;
  padding: 25px 20px;
  cursor: pointer;
}
.plan-button.active {
  font-weight: 600;
}
div.plan-button-group > div.plan-button:first-child {
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
  border-top: 1px solid #dcdcdc;
  border-top-left-radius: 5px;
}
div.plan-button-group > div.plan-button:last-child {
  border-right: 1px solid #dcdcdc;
  border-top: 1px solid #dcdcdc;
  border-top-right-radius: 5px;
}
div.plan-button-group.dark > div.plan-button:first-child {
  border-color: #fff;
}
div.plan-button-group.dark > div.plan-button:last-child {
  border-color: #fff;
}
.bg-dark {
  background: black;
}
.product-button-group {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  position: sticky;
  top: 66px;
  background: white;
  z-index: 100;
  transition: all;
}
.product-button-group.dark {
  box-shadow: rgb(255 255 255 / 10%) 0px 2px 4px;
  background: black;
}
.product-button-group > .product-button {
  width: 5vw;
  min-width: 10rem;
  padding: 1.2rem;
  cursor: pointer;
}
.product-button-group > .product-button.active {
  font-weight: 600;
  border-bottom: 4px solid #000;
}
.product-button-group.dark > .product-button.active {
  border-bottom: 4px solid #fff;
}
.pricing-card:first-child {
  border-right: 1px solid #dcdcdc;
}

.pricing-card:nth-child(2) {
  border-right: 1px solid #dcdcdc;
}

.see-features:first-child {
  border-right: 1px solid #dcdcdc;
}

.see-features:nth-child(2) {
  border-right: 1px solid #dcdcdc;
}

@import "../../../styles/variables.scss";

.user-permission-popup,
.team-permission-popup {
  .popup-header .fs-xl {
    font-size: 14px;
    font-weight: 600;
  }

  .user-role-selector {
    img {
      opacity: 0.4;
    }
  }
}

.team-permission-popup {
  .popup-body {
    .m-v-l {
      margin-bottom: 0px;
    }
  }
}

.user-role-selector {
  .listPickerBG {
    position: relative;
  }

  .option-actions {
    position: absolute;
    background: #f4f4f6;
    z-index: 1;
    right: 8px;
    height: 100%;
  }

  .user-role-selector-button text {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Previous share styles */
.info-text {
  display: none;
}

.permission-source-info > .info-icon:hover ~ .info-text {
  display: block;
}

.invite-user-section.row {
  flex-direction: row;
  align-items: flex-end;

  .row-responsive {
    margin-left: 8px;
  }
}

.listPickerBG.-selected {
  background-color: rgb(210, 228, 249);
}

@media screen and (max-width: $mobilebp) {
  .invite-user-section.row {
    flex-direction: column;
    align-items: flex-start;

    .row-responsive {
      margin-left: 0;
      margin-top: 4px;
      width: 100%;
    }
  }
}

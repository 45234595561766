.dk-animated-hero {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  /* margin: 20vh 0; */
  padding: "6rem 4rem 0";
}

.dk-animated-stack,
.animated-stack-title {
  font-size: 100px;
  font-weight: 700;
}
.cta-button {
  margin-top: 8rem;
}

.bg-stack-item-gray {
  background-color: #a4a29f;
}

.bg-lead-gray {
  background: rgba(142, 142, 152, 0.2);
}

.bg-silver-gray {
  background: rgb(71, 71, 76);
}

.stack-item-text-gray {
  color: #555145;
}

.dk-animated-stack {
  position: relative;
  perspective: 1000px;
  perspective-origin: 100% 400%;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
}

.dk-animated-stack-item {
  position: absolute;
  top: 0;
  left: 0;
  padding: 12px;
  border-radius: 8px;
  transition:
    opacity 0.3s ease-in,
    transform 0.3s ease-in;
}
.hero-page-header {
  font-size: 60px;
}
.text-section {
  width: 50%;
  padding: 4rem;
}
.hero-page-button {
  width: auto;
}
.showcase-slider {
  width: 100%;
  max-width: 80rem;
}
.showcase-title {
  font-size: 18px;
}
.showcase {
  padding: 2rem;
  max-width: 80rem;
}
.text-banner-title {
  font-size: 48px;
  text-align: center;
}
.text-banner-subtitle {
  text-align: center;
}
.eyebrow-label {
  text-align: center;
}
.text-banner-wrapper {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.text-image-title-wrapper {
  width: 50%;
}
.width-30 {
  width: 30%;
}
.width-45 {
  width: 45%;
}
.width-70 {
  width: 70%;
}
.padding-2 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.hero-page-button {
  justify-content: center;
}
@media only screen and (max-width: 800px) {
  .dk-animated-stack,
  .animated-stack-title {
    margin-top: 15px;
    font-size: 30px;
    font-weight: 700;
  }
  .dk-animated-hero {
    padding: 2rem 2rem 0px;
  }
  .cta-button {
    margin-top: 3rem;
  }
  .hero-page-header {
    font-size: 20px;
  }
  .text-section {
    width: 100%;
    padding: 2rem;
  }
  .hero-page-button {
    width: 100% !important;
    margin-right: 0;
    margin-bottom: 4px;
    /* justify-content: center; */
  }
  .showcase-slider {
    max-width: 11rem;
  }
  .showcase .ic-huge {
    height: 30px;
  }
  .showcase-title {
    font-size: 12px;
  }
  .text-banner {
    padding: 2rem;
  }
  .text-banner-title {
    font-size: 25px;
    text-align: left;
  }
  .text-banner-subtitle {
    text-align: left;
  }
  .eyebrow-label {
    text-align: left;
  }
  .text-banner-wrapper {
    padding: 0 !important;
  }
  .text-image-title-wrapper {
    width: 100%;
  }
  .width-30,
  .width-70,
  .width-45 {
    width: 100%;
  }
  .padding-2 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

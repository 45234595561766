@import "../../styles/variables.scss";
.setting-selected {
  background-color: #6518661a;
  &::before {
    content: "";
    position: absolute;
    display: inline-block;
    background-color: #651866;
    width: 3px;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.show-children {
  display: flex;
}

.hide-children {
  display: none;
}
@media screen and (max-width: $mobilebp) {
  .display-only-web.settings-search-bar {
    width: 100%;
    .textField {
    width: 100% !important;
    }
  }
}
.display-only-web.settings-search-bar {
  display: flex;
  .textField {
    font-size: 13px;
  }
}

.new-settings-wrapper {
  .textField {
    font-size: 13px;
  }
}

.setting-close-button {
  img {
    height: 12px;
  }
}

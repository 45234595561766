/* Display bulk edit popup fields in 2 columns */
div.bulk-record-update-popup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5%;

  & > div.row:first-child {
    grid-column: span 2;
  }

  /* positioning date pickers in 2nd column to avoid scroll */
  & > div[id^="dk-input"]:nth-child(2n + 1) #data-grid-date-editor {
    right: 0;
    left: unset !important;
  }
}

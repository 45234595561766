.dk-chat-ui-wrapper {
  .dk-chat-input-wrapper {
    .dk-chat-input {
      min-height: 38px;
      max-height: 100px;
      overflow-x: hidden;
      overflow-y: visible;
      font-size: 15px;
      font-weight: 400;
      word-wrap: break-word;
      white-space: pre-wrap;
      text-align: left;
      padding-left: 11px;
      &:focus {
        display: inline-block;
        vertical-align: top;
      }
    }
    .dk-chat-input[contenteditable="true"] {
      display: flex;
      align-items: flex-start;
      padding: 15px;
    }
    .dk-chat-input-send-btn {
      min-height: 20px;
    }
    .dk-chat-input-placeholder {
      position: absolute;
      font-size: 14px;
      padding: 11px;
      color: #4a4a4a;
      pointer-events: none;
      user-select: none;
      opacity: 0.8;
    }
  }
  .emoji-button {
    transition: transform 0.4s;
    &:hover {
      transform: scale(1.2);
    }
  }
  .justify-content-end {
    justify-content: flex-end;
  }
}

.document-container:hover {
  .download-button {
    display: flex !important;
  }
}

.flex-grow-1 {
  flex-grow: 1;
}
.li-card {
  list-style: none !important;
  box-shadow: 2px 2px 9px 0px #bbb;
  padding: 30px 10px;
  margin-bottom: 2px;
  // border-radius: 10px;
  position: absolute;
  width: 80px;
  // margin: 0px 5px;
}
.dk-chat-input[placeholder]:empty:before {
  content: attr(placeholder);
  color: gray;
  font-size: 16px;
  padding-top: 2px;
}

.dk-chat-input[placeholder]:empty:focus:before {
  content: "";
}
.chat-bubble-sender,
.chat-bubble-receiver {
  max-width: 80%;
  background-attachment: fixed;
  position: relative;
}
.chat-bubble-sender {
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.chat-bubble-receiver {
  border-bottom-right-radius: 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.chat-float-icon {
  transition: transform 0.5s ease;
}
.bubble-arrow {
  display: none;
}
.chat-bubble-sender:hover,
.chat-bubble-receiver:hover {
  .bubble-arrow {
    display: block;
  }
}
.chat-delete-icon {
  display: none;
}
.chat-list-wrap:hover {
  .chat-delete-icon {
    display: block;
  }
}
.chat-setting-input {
  .textField:focus {
    border-color: rgb(235, 235, 235);
    outline: none !important;
  }
  .textField {
    color: #2d2b25;
  }
}
.image-div {
  position: relative;
}
.image-div:hover .hidden-image {
  display: block;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-div .hidden-image {
  position: absolute;
  display: none;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.9);
}
.chat-bubble-sender a {
  color: #ffffff;
}

@import "../../styles/variables.scss";

.data-link-wrapper > a {
  height: 2rem;
  line-height: 1rem;
  overflow: hidden;

  &:visited {
    color: blue;
  }
}

.data-link-icon {
  display: none;
  margin-bottom: -6px;
  transition: 0.15s all;

  &:hover {
    height: 13px;
    margin-bottom: -8px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
}

.listPickerBG:hover .data-link-icon {
  display: inline-block;
}

.dashboard-card-wrapper > .dashboard-card {
  width: 32%;
  margin-top: 1%;
  margin-bottom: 1%;
  border-color: #ffffff;
  /* margin-right: 1%; */

  @media (max-width: $largebp) {
    width: 49%;
  }

  @media (max-width: $mobilebp) {
    width: 100%;
    margin-bottom: 2%;
  }

  &:nth-child(3n) {
    margin-right: 0%;
  }
}

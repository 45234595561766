.timeout-background {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
}

.timeout-popup {
  position: fixed;
  width: 310px;
  min-width: 310px;
  max-width: 80vw;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 12px rgba(0, 0, 20, 0.14);
  font-size: 14px;
  text-align: left;
}

.timer-fs {
  font-size: 2.4rem;
  font-weight: 300;
}

.border-top {
  border-radius: 3px 3px 0px 0px;
}

.border-bottom {
  border-radius: 3px 3px;
}

/* Display Utils */
.d-flex {
  display: flex;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.max-width-100 {
  max-width: 100%;
}

.max-screen-height-90 {
  max-height: 90vh;
}

.min-height-parent {
  min-height: 100%;
}

/* Margin util: */
.mt-auto {
  margin-top: auto !important;
}
.mb-auto {
  margin-bottom: auto !important;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto !important;
}

.p-h-strict-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* Borders */
.border-m-b {
  border: 0 solid rgb(220, 220, 220);
  border-bottom-width: 1px;
}

/* Cursor Styles */
.cursor-grabbing {
  cursor: grabbing;
}

.cursor-move {
  cursor: move;
}

/* Text Styles */
.text-underline {
  text-decoration: underline;
}

.input-underline {
  border-bottom: 1px solid #dcdcdc;
}

/* Focus Utils */
.hide-outline {
  outline: none;
}

.hover-actions-container {
  .action-wrapper {
    visibility: hidden;
  }

  &:hover .action-wrapper {
    visibility: visible;
  }
}

/* transitions */
.rotate-180 {
  transform: rotate(180deg);
}
.rotate-360 {
  transform: rotate(360deg);
}
.rotate-138 {
  transform: rotate(138deg);
}

.white-space-no-wrap {
  white-space: nowrap !important;
}

@media screen and (max-width: $mobilebp) {
  .justify-content-center-mobile {
    justify-content: center !important;
  }

  .align-items-center-mobile {
    align-items: center !important;
  }

  .padding-empty-mobile {
    padding: 0px !important;
  }

  .margin-empty-mobile {
    margin: 0px !important;
  }

  .mobile-parent-width {
    width: 100% !important;
  }

  .mobile-max-width-100 {
    max-width: 100% !important;
  }

  .justify-content-end-mobile {
    justify-content: flex-end !important;
  }

  .justify-content-start-mobile {
    justify-content: flex-start !important;
  }

  .mobile-height-auto {
    height: auto !important;
  }
}

@media screen and (max-width: $smallbp) {
  .width-full-mobile {
    width: 100% !important;
  }

  .mobile-left-0 {
    left: 0 !important;
    right: unset !important;
  }
}

.tracking-normal {
  letter-spacing: 0;
}

.font-normal {
  font-weight: 400;
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219);
}
.border {
  border-width: 1px;
}
.rounded {
  border-radius: 0.25rem;
}
.ring-red-500 {
  border: 3px solid #f00;
}
.text-transparent {
  color: white;
}
.opacity-50 {
  opacity: 0.5;
}

.gap-m {
  gap: 16px;
}

.gap-s {
  gap: 8px;
}

@import "../../styles/variables.scss";

.mobile-app-theme-color {
  background-color: #2c1f47;
}

.dk-desk-tutorial {
  right: 150px;
  top: 50px;
  width: 420px;
}

.left-menu-icon {
  /* for aligning all menu item titles */
  width: 20px;
}

.has-sub-menu,
.second-last-menu-item {
  margin-bottom: 8px;
}

@media screen and (max-width: $mobilebp) {
  .dk-sidebar-toggle.expandable-button {
    visibility: visible;
  }

  .dk-desk-tutorial {
    width: 100%;
    right: 0;
  }
}
